<template>
    <div>
        <template v-if="nutriScore === 'A' || 'a'">
            <img src="../../assets/img/nutriscore/1200px-Nutri-score-A.svg.png" alt="" srcset="">
        </template>
        <template v-else-if="nutriScore === 'B' || 'b'">
            <img src="../../assets/img/nutriscore/2560px-Nutri-score-B.svg.png" alt="" srcset="">
        </template>
        <template v-else-if="nutriScore === 'C' || 'c'">
            <img src="../../assets/img/nutriscore/2560px-Nutri-score-C.svg.png" alt="" srcset="">
        </template>
        <template v-else-if="nutriScore === 'D' || 'd'">
            <img src="../../assets/img/nutriscore/2560px-Nutri-score-D.svg.png" alt="" srcset="">
        </template>
        <template v-else-if="nutriScore === 'E' || 'e'">
            <img src="../../assets/img/nutriscore/2560px-Nutri-score-E.svg.png" alt="" srcset="">
        </template>
        <template v-else>
            <div></div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AppNutriScore',

    props: {
        nutriScore: {
            type: String,
            require: true
        }
    }
}
</script>