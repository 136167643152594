<template>
    <app-data-detail-product v-if="displayContent" :detail-product="displayContent" />
</template>

<script>
import AppDataDetailProduct from '@/components/Data/AppDataDetailProduct.vue';

export default {
    name: 'AppListDetailProduct',

    components: {
        AppDataDetailProduct
    },

    props: {
        detailProduct: {
            type: Object,
            require: true
        }
    },

    computed: {
        displayContent() {
            return this.detailProduct?.transactionOrder;
        },
    },
}
</script>