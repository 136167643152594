<template>
    <div class="accordion">
        <div class="accordion-list">
            <button class="accordion-pieces" @click="toggleAccordion">
                <div class="accordion-piecesText">
                    <slot name="title" />
                </div>

                <div class="accordion-arrow">
                    <div class="accordion-arrowText">
                        <slot name="price" />
                    </div>
                    <img src="../../assets/img/icons/fleche_pt.png" alt="" srcset="" 
                        class="accordion-arrowImg"
                        :class="{
                            'turn': isOpen
                        }"
                    >
                </div>
            </button>

            <div v-show="isOpen" :id="`collapse-${_uid}`">
                <slot name="content" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppAccordion',

    data() {
        return {
            isOpen: false,
        };
    },

    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        }
    }
}
</script>