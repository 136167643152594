<template>
    <app-card v-if="detailProduct">
        <!-- Used -->
        <template v-slot:point>
            {{ isExistPoint(detailProduct.totalRewardPoint) }} 
        </template>
        
        <template v-slot:value_point>
            {{ isExistValue(detailProduct.totalRewardInternalCash) }}
        </template>
    </app-card>
</template>

<script>
import AppCard from '@/components/Card/AppCard.vue';

export default {
    name: 'AppDataFidelity',

    components: {
        AppCard
    },

    props: {
        detailProduct: {
            type: Object,
            require: true
        }
    },

    computed: {
        fidelity() {
           return this.detailProduct;
        }
    },

    methods: {
        roundedTwoValue(value) {
            return (Math.round(value * 100) / 100).toFixed(2);
        },

        isExistPoint(value) {
            return (value !== null && value !== undefined) ? this.roundedTwoValue(value) + ' pts' : 'N/C*';
        },

        isExistValue(value) {
            return (value !== null && value !== undefined) ? this.roundedTwoValue(value) + ' €' : 'N/C*';
        }
    }
}
</script>