import Vue from 'vue'
import VueRouter from 'vue-router'
import DesignSpec from "../views/DesignSpec.vue"
import DetailTransaction from "../views/DetailTransaction.vue"
import DetailProduct from "../views/DetailProduct.vue"
import NotFound from "../views/NotFound.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/ui',
    name: 'DesignSpec',
    component: DesignSpec
  },
  {
    path: '/transaction/:id',
    name: 'DetailTransaction',
    component: DetailTransaction
  },
  {
    path: '/product/:slug',
    name: 'DetailProduct',
    component: DetailProduct
  },
  { 
    path: '*', 
    component: NotFound 
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router






