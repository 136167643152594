<template>
    <div class="dataTotalPayment" v-if="detailProduct">
        <div class="dataTotalPayment-total">
            <div>A Payer</div>
            {{ detailProduct.reduction }}
            <div>{{ roundedTwoValue(totalAmountPaid) }}</div>
        </div>

        <div class="dataTotalPayment-totalOutTax">
            <div class="dataTotalPayment-content">
                <div><i>Dont TVA</i></div>
                <div><i>{{ isExist(totalTva) }}</i></div>
            </div>
            <div class="dataTotalPayment-content">
                <div>Total HT</div>
                <div>{{ isExist(totalOutTaxes) }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDataTotalPayment',

    props: {
        detailProduct: {
            type: Object,
            require: true
        }
    },

    computed: {
        totalReduction(){
            if(!this.detailProduct) return;

            var reductions = 0;
                this.detailProduct.transactionOrderLines.forEach((item) => {
                    if (item.reduction < 0) {
                        reductions += item.reduction;
                    }
                });
               
            return reductions;
        },
        totalAmountPaid() {
            if(!this.detailProduct) return;


            return this.detailProduct?.totalAmountPaid + this.totalReduction;
        },
        totalTva() {
            if(!this.detailProduct) return;

            let sumTva = 0;

            for (let j  = 0; j < this.detailProduct?.transactionOrderLines.length; j++) {
                sumTva += this.detailProduct?.transactionOrderLines[j]?.tva;
            }

            return  sumTva;
        },
        totalOutTaxes() {
            return this.totalAmountPaid + this.totalReduction - this.totalTva;
        }
    },

    methods: {
        isExist(value) {
            return (value !== null && value !== undefined) ? this.roundedTwoValue(value) + ' €' : 'N/C*';
        },
        isExistTotal(value) {
            return (value !== null && value !== undefined) ? value + ' €' : 'N/C*';
        },
        roundedTwoValue(value) {
            return (Math.round(value * 100) / 100).toFixed(2);
        },
    }
}
</script>