<template>
    <mystore-information v-if="detailProduct">
        <template v-slot:product>
            {{ transaction.type }}
        </template>

        <template v-slot:pos>
           {{ detailProduct.pos }}
        </template>

        <template v-if="transaction.address" v-slot:address>
            {{ isExist(transaction.address) }}
        </template>

        <template v-if="transaction.postal_code" v-slot:code>
            {{ isExist(transaction.postal_code) }}
        </template>

        <template v-if="transaction.date" v-slot:dateTime>
            {{ dateFormat(transaction.date) }} - {{ timeFormat(transaction.date) }}
        </template>

        <template v-if="detailProduct.transactionOrderLines" v-slot:nbArticles>
           {{ isExist(detailProduct.productQuantity) }} / {{ isExist(detailProduct.transactionOrderLines.length) }}
        </template>

    </mystore-information>

    <mystore-information v-else />
</template>

<script>
import MystoreInformation from '@/components/Layouts/MystoreInformation.vue'
export default {
    name: 'AppDataHeaderStoreInformation',
    components: { MystoreInformation },

    props: {
        detailProduct: {
            type: Object,
            default: null
        }
    },

    computed: {
        content() {
            return this.detailProduct;
        },
        transaction() {
            return this.detailProduct
        }
    },

    methods: {
        dateFormat(value) {
            return new Date(value).toLocaleDateString();
        },

        timeFormat(value) {
            return new Date(value).toLocaleTimeString();
        },

        isExist(value) {
            return (value !== null && value !== undefined) ? value : '-';
        }
    }
}
</script>