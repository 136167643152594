import { getTransactionOrder, getDetailProduct } from "../../api/Receipts";

//const actions
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const GET_TEMPLATE_PRODUCT = 'GET_TEMPLATE_PRODUCT';

//const mutations
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_DETAIL_PRODUCT_CONTENT = 'SET_DETAIL_PRODUCT_CONTENT';
export const SET_TEMPLATE_PRODUCT = 'SET_TEMPLATE_PRODUCT';
export const SET_DETAIL_PRODUCT_COLOR = "SET_DETAIL_PRODUCT_COLOR";

const state = {
    detailsTransaction: {},
    detailProductContent: {},
    detailTemplateProduct: {},
    css_id: "",
}

//getters
const getters = {
    displayDetailsTransaction: (state) => {
        return state.detailsTransaction;
    },
    displayDetailProduct: (state) => {
        return state.detailProductContent;
    },
    displayInfoTemplateProduct: (state) => {
        return state.detailTemplateProduct;
    },
    displayCssId: (state) => {
        return state.css_id;
    }
};

//actions
const  actions = {
    async [GET_TRANSACTION]({ commit }, {token}) {

        const transaction = await getTransactionOrder(token);

        commit('SET_TRANSACTION', transaction);

        return transaction;
    },

    async [GET_TEMPLATE_PRODUCT]({ commit }, { slug }) {

        const detailProduct = await getDetailProduct(slug);

        commit('SET_TEMPLATE_PRODUCT', detailProduct);

        return detailProduct;
    }
}

//mutations
const mutations = {
    [SET_TRANSACTION]: (state, transaction) => state.detailsTransaction = transaction,

    [SET_DETAIL_PRODUCT_CONTENT]: (state, detail) => state.detailProductContent = detail,
   
    [SET_TEMPLATE_PRODUCT]: (state, slug) => {
        state.detailTemplateProduct = slug;
    },

    [SET_DETAIL_PRODUCT_COLOR]: (state, value) => {
        state.css_id = value
    },
};

export default {
    state, 
    getters, 
    actions, 
    mutations
}