<template>
    <div class="cardComplete">
        <h2 class="cardComplete-subtitleTwo">Fidélité</h2>
        <div class="cardComplete-fidelityHeaderUsed">
            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOneHeader">Utilisé</div>
                <div class="cardComplete-scopeTwo">
                    <div class="cardComplete-scopeOneHeader">Points</div>
                    <div class="cardComplete-scopeOneHeader space">Prix</div>
                </div>
            </div>
            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOne">Point</div>
                <div class="cardComplete-scopeTwo">
                    <div class="cardComplete-scopeDetail">-4</div>
                    <div class="cardComplete-scopeDetail">-4, 00 €</div>
                </div>
            </div>
            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOne">Euros</div>
                <div class="cardComplete-scopeTwo">
                    <div></div>
                    <div class="cardComplete-scopeDetail">-2, 50 €</div>
                </div>
            </div>
        </div>
        <div class="cardComplete-fidelityHeaderGain">

            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOneHeader">Gagné</div>
                <div class="cardComplete-scopeTwo">
                    <div class="cardComplete-scopeOneHeader">Points</div>
                    <div class="cardComplete-scopeOneHeader space">Prix</div>
                </div>
            </div>
            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOne">Point</div>
                <div class="cardComplete-scopeTwo">
                    <div class="cardComplete-scopeDetail">-4</div>
                    <div class="cardComplete-scopeDetail">-4, 00 €</div>
                </div>
            </div>
            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOne">Euros</div>
                <div class="cardComplete-scopeTwo">
                    <div></div>
                    <div class="cardComplete-scopeDetail">-2, 50 €</div>
                </div>
            </div>
        </div>
        <div class="cardComplete-fidelityHeaderCash">

            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOneHeader">Ma cagnotte</div>
                <div class="cardComplete-scopeTwo">
                    <div class="cardComplete-scopeOneHeader">Points</div>
                    <div class="cardComplete-scopeOneHeader space">Prix</div>
                </div>
            </div>
            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOneCash">Point</div>
                <div class="cardComplete-scopeTwo">
                    <div>-4</div>
                    <div>-4, 00 €</div>
                </div>
            </div>
            <div class="cardComplete-scopes">
                <div class="cardComplete-scopeOneCash">Euros</div>
                <div class="cardComplete-scopeTwo">
                    <div></div>
                    <div>-2, 50 €</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppCardComplete"
}
</script>