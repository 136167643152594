<template>
    <div class="detailTransaction" v-if="!isLoading && authorization">
        <div class="detailTransaction-card" v-if="transaction">
            <Header />

            <div class="detailTransaction-content">
                <app-header-store-information :detail-product="transaction" />

                <app-list-detail-product :detail-product="transaction" />

                <app-data-detail-payment :detail-product="detailTransactionOrder" />

                <app-data-total-payment :detail-product="detailTransactionOrder" />

                <app-data-fidelity :detail-product="detailTransactionOrder" />
            </div>
        </div>

        <div v-else class="detailTransaction-notDataFound">
            N/C*: Données non communiquées
        </div>
    </div>
    
    <app-error-session v-else-if="!isLoading && !authorization" />

    <app-global-loader v-else />
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_TRANSACTION, SET_DETAIL_PRODUCT_COLOR
} from '../store/modules/receipts';

import Header from "@/components/Layouts/Header.vue";
import AppErrorSession from "@/components/Message/Error/AppErrorSession.vue";
import AppHeaderStoreInformation from '@/components/Header/AppHeaderStoreInformation.vue';
import AppListDetailProduct from '@/components/List/AppListDetailProduct.vue';
import AppDataDetailPayment from '@/components/Data/AppDataDetailPayment.vue';
import AppDataTotalPayment from '@/components/Data/AppDataTotalPayment.vue';
import AppDataFidelity from '@/components/Data/AppDataFidelity.vue';
import AppGlobalLoader from '@/components/Loader/AppGlobalLoader.vue';




export default {
    name: "DetailTransaction",
    components: {
        Header,
        AppErrorSession,
        AppHeaderStoreInformation,
        AppListDetailProduct,
        AppDataDetailPayment,
        AppDataTotalPayment,
        AppDataFidelity,
        AppGlobalLoader,
    },

    data() {
        return {
            isLoading: false,
            theme: null
        };
    },

    async mounted() {
      await this.allDetailsTransaction();
      this.theme = this.displayCss;

      this.handleSelect();

      this.$store.commit(SET_DETAIL_PRODUCT_COLOR, this.theme);
    },

    computed: {
        ...mapGetters([
            'displayDetailsTransaction',
        ]),
        authorization() {
            return this.displayDetailsTransaction?.authorization;
        },
        detailTransactionOrder() {
            return this.displayDetailsTransaction?.transactionOrder;
        },
        transaction() {
            if(!this.displayDetailsTransaction) return;

            return this.displayDetailsTransaction;
        },
        displayCss() {
            return this.displayDetailsTransaction?.css;
        }
    },

    methods: {
        async allDetailsTransaction() {
            this.isLoading = true;

            try {
                await this.$store.dispatch(GET_TRANSACTION, {token: this.$route.params.id});

                this.isLoading = false;
            } catch (error) {
                console.log(error);
                this.isLoading = false;
            }          
        },

        handleSelect() {
            this.darkMode = !this.darkMode
            document.documentElement.setAttribute('data-theme', this.theme)
        }
    },
}
</script>