<template>
    <div class="dataDetailProductContent">
        <div v-if="isExist(detailProduct.quantityPaid)" class="dataDetailProductContent-productInfos">
            <div>Quantité</div>
            <div>{{ isExist(detailProduct.quantityPaid) }}</div>
        </div>
        <div v-if="isExist(detailProduct.unitPrice)" class="dataDetailProductContent-productInfos">
            <div>Prix unitaire</div>
            <div>{{ isExist(roundedTwoValue(detailProduct.unitPrice)) }} €</div>
        </div>
        <div v-if="isExist(detailProduct.totalPrice)" class="dataDetailProductContent-productInfos">
            <div>Montant avant remise</div>
            <div>{{ isExist(roundedTwoValue(detailProduct.totalPrice)) }} €</div>
        </div>
        <div v-if="isExist(detailProduct.reduction)" class="dataDetailProductContent-productInfos">
            <div>Remise immédiate</div>
            <div>{{ isExist(roundedTwoValue(detailProduct.reduction)) }} €</div>
        </div>
        <div v-if="roundedTwoValue(detailProduct.tvaRate)" class="dataDetailProductContent-productInfos">
            <div>TVA en %</div>
            <div>{{ roundedTwoValue(detailProduct.tvaRate) }}%</div>
        </div>
        <div v-if="roundedTwoValue(detailProduct.tva)" class="dataDetailProductContent-productInfos">
            <div>Montant TVA</div>
            <div>{{ roundedTwoValue(detailProduct.tva) }} € </div>
        </div>
        <div v-if="isExist(detailProduct.rewardPoint)" class="dataDetailProductContent-productInfos">
            <div>Gain points</div>
            <div>{{ isExist(detailProduct.rewardPoint) }} pts</div>
        </div>
        <div v-if="detailProduct.rewardCash" class="dataDetailProductContent-productInfos">
            <div>Gain €</div>
            <div>{{ roundedTwoValue(detailProduct.rewardCash) }} €</div>
        </div>

        <a 
            v-if="isValidSlug"
            class="dataDetailProductContent-link"
            @click="goTemplateProduct"
        >
            <div class="dataDetailProductContent-linkText">Voir la fiche produit</div>
        </a>
    </div>
</template>

<script>
import {
  GET_TEMPLATE_PRODUCT,
  SET_DETAIL_PRODUCT_CONTENT,
} from '../../store/modules/receipts';

export default {
    name: 'AppDataDetailProductContent',

    props: {
        detailProduct: {
            type: Object,
            require: true
        }
    },

    async mounted() {
        Object.assign(this.detailProduct, {token_url: this.$route.params.id})
        await this.detailTemplateProduct(this.slug);
    },

    computed: {
        isValidSlug() {
            return (this.detailProduct?.articleCode !== "" 
            && this.detailProduct?.articleCode !== undefined) && this.detail?.productDetails; 
        }
    },

    data() {
        return {
            slug: this.detailProduct?.articleCode,
            detail: null
        };
    },

    methods: {
        goTemplateProduct() {
            
            Object.assign(this.detailProduct, {token_url: this.$route.params.id})
            this.$store.commit(SET_DETAIL_PRODUCT_CONTENT, this.detailProduct);
            this.$router.push({ path: `/product/${this.slug}` });
        },

        async detailTemplateProduct(slug) {
            if(slug && slug !== null) {
                this.detail = await this.$store.dispatch(GET_TEMPLATE_PRODUCT, {
                    slug,
                });
            }
        },

        isDisabled() {
            return this.slug !== "" ? true : false;
        },

        roundedTwoValue(value) {
            return (Math.round(value * 100) / 100).toFixed(2);
        },

        isExist(value) {
            return (value !== "" && value !== null)  ? value : 0;
        }
    }
}
</script>