<template>
    <div v-if="info">
        <div class="templateProduct-nameProduct">
            {{ info.productName }}
        </div>

        <div class="templateProduct-imagesProduct" v-if="!isLoading">
            <img :src="info.imageUrl" alt="" srcset="" class="templateProduct-imagePrincipalProduct">

            <div class="templateProduct-scoreProduct">
                <app-nutri-score 
                    v-if="info.nutriscoreGrade"
                    :nutri-score="info.nutriscoreGrade" 
                    class="templateProduct-imgNutriScoreProduct"
                />

                <app-nova-score 
                    v-if="info.novaGroup" 
                    :nova-score="info.novaGroup" 
                    class="templateProduct-imgNovaScoreProduct"
                 />

                <app-eco-score 
                    v-if="info.ecoscoreGradeFr" 
                    :eco-score="info.ecoscoreGradeFr" 
                    class="templateProduct-imgEcoScoreProduct" 
                />
            </div>
        </div>

        <app-loader v-else />
    </div>
</template>

<script>
import AppLoader from "@/components/Loader/AppLoader";
import AppNutriScore from "@/components/Score/AppNutriScore";
import AppNovaScore from "@/components/Score/AppNovaScore";
import AppEcoScore from "@/components/Score/AppEcoScore";

export default {
    name: 'AppDataScoreProduct',

    components: {
        AppNutriScore,
        AppNovaScore,
        AppEcoScore,
        AppLoader,
    },

    props: {
        info: {
            type: Object,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        }
    }
}
</script>