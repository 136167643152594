<template>
  <div class="messageErrorSession">
    <div class="messageErrorSession-wrapper">
      <div class="messageErrorSession-content">
        <div class="messageErrorSession-section">
          <div class="messageErrorSession-title">
            <img
              src="../../../assets/img/icons/point-dexclamation-dans-un-cercle.png"
            />
            <div class="messageErrorSession-text">Session expirée&nbsp;!</div>
          </div>

          <!-- <div class="messageErrorSession-subtitle">
            <p class="messageErrorSession-subtitleOne">
              Votre session a expiré. Vous pouvez vous reconnecter et redémarrer
              votre enrolement
            </p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppErrorSession",
};
</script>