<template>
    <div class="py-8 flex min-h-[50%] md:min-h-full justify-center items-center">
        <div class="text-2xl text-blue-400">Aucune information</div>
    </div>
</template>

<script>
export default {
    name: 'AppErrorNoContent'
}
</script>