<template>
    <div class="flex min-h-screen justify-center items-center">
        <div class="text-2xl text-blue-600">404 | </div>
        <div class="text-2xl">Page non trouvé</div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>