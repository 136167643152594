<template>
    <div class="storeInformation">
        <h1 class="storeInformation-subtitleOne">
            <slot name="product" />
        </h1>

        <h6 class="storeInformation-subtitleSix">
            <slot name="pos" />
        </h6>

        <h6 class="storeInformation-subtitleSix address">
            <slot name="address" />
        </h6>
        <h6 class="storeInformation-subtitleSix">
            <slot name="code" />
        </h6>

        <p class="storeInformation-subtitleSeven">
            <slot name="dateTime" />
        </p>

        <p class="storeInformation-subtitleSeven">
            <slot name="nbArticles" />
        </p>
    </div>
</template>

<script>
export default {
    name: 'MyStoreInformation',
}
</script>