<template>
    <app-data-header-store-information v-if="displayInfos" :detail-product="displayInfos" />
</template>

<script>
import AppDataHeaderStoreInformation from '@/components/Data/AppDataHeaderStoreInformation.vue';

export default {
    name: 'AppHeaderStoreInformation',

    components: {
        AppDataHeaderStoreInformation
    },

    props: {
        detailProduct: {
            type: Object,
            require: true
        }
    },

    computed: {
        displayInfos() {
            return this.detailProduct?.transactionOrder;
        },
    }
}
</script>