<template>
    <div v-if="category" class="dataDetailProduct">
        <div v-for="item  in category" :key="item.id">
            <h5 class="dataDetailProduct-subtitleFive">{{ category.contentCategory }}</h5>

            <app-accordion>
                <template v-if="item.label" v-slot:title>
                    {{ item.label }}
                </template>

                <template v-if="item.totalPrice" v-slot:price>
                    {{ roundedTwoValue(item.totalPrice + item.reduction) }} €
                </template>

                <template v-slot:content>
                    <app-data-detail-product-content :detail-product="item" />
                </template>
            </app-accordion>
        </div>
    </div>
</template>

<script>
import AppAccordion from "@/components/Accordion/AppAccordion";
import AppDataDetailProductContent from "@/components/Data/AppDataDetailProductContent";

export default {
    name: 'AppDataDetailProduct',

    components: {
        AppAccordion,
        AppDataDetailProductContent
    },

    props: {
        detailProduct: {
            type: Object,
            require: true
        }
    },

    computed: {
        category() {
           return this.detailProduct?.transactionOrderLines;
        }
    },
    methods: {
        roundedTwoValue(value) {
            return (Math.round(value * 100) / 100).toFixed(2);
        }
    }
}
</script>