<template>
    <div>
        <template v-if="ecoScore === 'A'">
            <img src="../../assets/img/ecoscore/A.png" alt="" srcset="">
        </template>
        <template v-else-if="ecoScore === 'B'">
            <img src="../../assets/img/ecoscore/B.png" alt="" srcset="">
        </template>
        <template v-else-if="ecoScore === 'C'">
            <img src="../../assets/img/ecoscore/C.png" alt="" srcset="">
        </template>
        <template v-else-if="ecoScore === 'D'">
            <img src="../../assets/img/ecoscore/D.png" alt="" srcset="">
        </template>
        <template v-else-if="ecoScore === 'E'">
            <img src="../../assets/img/ecoscore/E.png" alt="" srcset="">
        </template>
        <template v-else>
            <div></div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AppEcoScore',

    props: {
        ecoScore: {
            type: String,
            require: true
        }
    }
}
</script>