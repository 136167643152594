<template>
    <div class="templateProduct-infoProduct">
        <ul v-if="info">
            <li>
                <app-data-detail-product-benchmark :info="info" />
            </li>

            <li>
                <app-data-detail-benchmark-product-value :info="info" />
            </li>

            <li>
               <app-data-detail-ingredient-product :info="info" />
            </li>
        </ul>

        <div v-else class="templateProduct-noContent">
            Aucune information
        </div>
    </div>
</template>

<script>
import AppDataDetailIngredientProduct from "@/components/Data/AppDataDetailIngredientProduct";
import AppDataDetailProductBenchmark from "@/components/Data/AppDataDetailProductBenchmark";
import AppDataDetailBenchmarkProductValue from "@/components/Data/AppDataDetailBenchmarkProductValue";

export default {
    name: 'AppListIngredients',

    components: {
        AppDataDetailProductBenchmark,
        AppDataDetailIngredientProduct,
        AppDataDetailBenchmarkProductValue
    },

    props: {
        info: {
            type: Object,
            required: true
        }
    },
}
</script>