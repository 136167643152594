<template>
    <div class="texts">
        <div class="texts-section">
            <span class="texts-title">h1</span>
            <h1 class="texts-subtitleOne">Auchan st genis</h1>
        </div>
        <div class="texts-section">
            <span class="texts-title">h2</span>
            <h2 class="texts-subtitleTwo">Fidélité</h2>
        </div>
        <div class="texts-section">
            <span class="texts-title">h3</span>
            <h3 class="texts-subtitleThree">FRUITS & LÉGUMES</h3>
        </div>
        <div class="texts-section">
            <span class="texts-title">h4</span>
            <h4 class="texts-subtitleFour">Concombre</h4>
        </div>
        <div class="texts-section">
            <span class="texts-title">h5</span>
            <h5 class="texts-subtitleFive">DÉTAILS DES ACHATS</h5>
        </div>
        <div class="texts-section">
            <span class="texts-title">h6</span>
            <h6 class="texts-subtitleSix">Remise immédiate Coupon de réduction</h6>
        </div>
        <div class="texts-section">
            <span class="texts-title">p</span>
            <p class="texts-subtitleSeven">Vérifier votre identité auprès de votre marchand, il attend une action de votre part pour pouvoir vous transmettre vos données.</p>
        </div>
        <div class="texts-section">
            <span class="texts-title">legend</span>
            <legend class="texts-subtitleEight">05/12/2021</legend>
        </div>
    </div>
</template>

<script>
export default {
    name: "AppTexts"
}
</script>