<template>
    <div class="templateProduct-header">
        <button @click="previousLink">
            <img src="../../assets/img/icons/back.png" alt="" srcset="">
        </button>
        <h5 class="templateProduct-subtitleFive">FICHE PRODUIT</h5>
    </div>
</template>

<script>
export default {
    name: 'AppDataHeaderTemplateProduct',

    methods: {
        previousLink() {
            this.$emit('previousLink');
        }
    }
}
</script>