<template>
    <div class="accordionComplete" :class="{'turn': isOpen}">
        <ul class="accordionComplete-list">
            <li>
                <button class="accordionComplete-pieces" @click="toggleAccordion">
                    <div class="accordionComplete-piecesText">Fruits & légumes</div>
                    <div class="accordionComplete-arrow">
                        <div class="accordionComplete-arrowText">0.10 €</div>
                        <img src="../../assets/img/icons/fleche_pt.png" alt="" srcset="" 
                            class="accordionComplete-arrowImg"
                            :class="{
                                'turn': isOpen
                            }"
                        >
                    </div>
                </button>

                <div v-show="isOpen" :id="`collapse-${_uid}-1`">
                    <div class="accordionComplete-productInfos">
                        <div>Quantité</div>
                        <div>1 kg</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Prix unitaire</div>
                        <div>1,10 €</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>TVA en %</div>
                        <div>19,6%</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Montant TVA</div>
                        <div>0,24 €</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Remise immédiate</div>
                        <div>- 1, 00 €</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Gain points</div>
                        <div>3 pts</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Gain en €</div>
                        <div>0,24 €</div>
                    </div>

                    <button class="accordionComplete-button">
                        <div class="accordionComplete-buttonText">Voir la fiche produit</div>
                        <img src="../../assets/img/icons/Icon awesome-arrow-left.png" alt="">
                    </button>
                </div>
            </li>
            <li>
                <button class="accordionComplete-pieces" @click="toggleAccordionTwo">
                    <div class="accordionComplete-piecesText">Agrumes</div>
                    <div class="accordionComplete-arrow">
                        <div class="accordionComplete-arrowText">0.10 €</div>
                        <img src="../../assets/img/icons/fleche_pt.png" alt="" srcset="" 
                            class="accordionComplete-arrowImg"
                            :class="{
                                'turn': isOpenTwo
                            }"
                        >
                    </div>
                </button>

                <div v-show="isOpenTwo" :id="`collapse-${_uid}-2`">
                    <div class="accordionComplete-productInfos">
                        <div>Quantité</div>
                        <div>1 kg</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Prix unitaire</div>
                        <div>1,10 €</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>TVA en %</div>
                        <div>19,6%</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Montant TVA</div>
                        <div>0,24 €</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Remise immédiate</div>
                        <div>- 1, 00 €</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Gain points</div>
                        <div>3 pts</div>
                    </div>
                    <div class="accordionComplete-productInfos">
                        <div>Gain en €</div>
                        <div>0,24 €</div>
                    </div>

                    <button 
                        class="accordionComplete-button" 
                        @click="displayDialog" 
                        :disabled="!isOpen" 
                        :class="{ 'color': !isOpen }"
                        >
                        <div class="accordionComplete-buttonText">Voir la fiche produit</div>
                        <img src="../../assets/img/icons/Icon awesome-arrow-left.png" alt="">
                    </button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'AppAccordionComplete',

    data() {
        return {
            isOpen: false,
            isOpenTwo: false,
        };
    },

    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        },
        
        toggleAccordionTwo() {
            this.isOpenTwo = !this.isOpenTwo;
        },

        displayDialog() {
            alert("Yes we can!");
        }
    }
}
</script>