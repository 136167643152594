<template>
    <div>
        <template v-if="novaScore === 1">
            <img src="../../assets/img/novascore/100px-NOVA_group_1.svg.png" alt="" srcset="">
        </template>
        <template v-else-if="novaScore === 2">
            <img src="../../assets/img/novascore/1200px-NOVA_group_2.svg.png" alt="" srcset="">
        </template>
        <template v-else-if="novaScore === 3">
            <img src="../../assets/img/novascore/611px-NOVA_group_3.svg.png" alt="" srcset="">
        </template>
        <template v-else-if="novaScore === 4">
            <img src="../../assets/img/novascore/1200px-NOVA_group_4.svg.png" alt="" srcset="">
        </template>
        <template v-else>
            <div></div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AppNovaScore',

    props: {
        novaScore: {
            type: Number,
            require: true
        }
    }
}
</script>