<template>
     <app-accordion>
        <template v-slot:title>
            Liste des ingrédients
        </template>
        <template v-slot:content>
            <div v-if="info.ingredientsText" class="templateProduct-detailsComponent">
                {{ info.ingredientsText }}
            </div>

            <div v-else class="templateProduct-noContent">
                Aucune information
            </div>
        </template>
    </app-accordion>
</template>

<script>
import AppAccordion from "@/components/Accordion/AppAccordion";

export default {
    name: 'AppDataDetailIngredientProduct',

    components: {
        AppAccordion
    },

    props: {
        info: {
            type: Object,
            required: true
        }
    },
}
</script>