<template>
  <div class="loader_mini-wrapper">
    <div class="loader_mini-container">
      <img src="../../assets/img/icons/loading.png" class="loader_mini-icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppLoader",
};
</script>