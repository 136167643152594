<template>
    <div class="globalLoader-wrapper">
    <div class="globalLoader-container">
      <img src="../../assets/img/icons/loading.png" class="globalLoader-icon" />
    </div>
  </div>
</template>

<script>
export default {
    name: 'AppGlobalLoader'
}
</script>