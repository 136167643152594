import Vue from 'vue'
import App from './App.vue'
import store from './store'
import './assets/scss/index.scss'
import router from './router'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { faShoppingCart, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faShoppingCart, faCircleXmark)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import Skeleton from 'vue-loading-skeleton';

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.config.productionTip = false;

export const eventBus = new Vue();

new Vue({
  render: h => h(App),
  store,
  router,
  Skeleton
}).$mount('#app')
