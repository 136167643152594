<template>
    <div class="designSpec">
        <div class="designSpec-card">
            <select
                id="themes"
                v-model="theme"
                name="themes"
                @click="handleSelect"
            >
                <option value="light">Light</option>
                <option value="dark">Dark</option>
            </select>

            <div class="designSpec-content">
                <header class="designSpec-header" >
                    <div class="designSpec-principalPicture">
                        <img src="../assets/img/logo/logo_unnidata.png" alt="" srcset="">
                    </div>
                    <div class="designSpec-icons">
                        <img src="../assets/img/icons/back.png" alt="" srcset="" class="">
                        <img src="../assets/img/icons/close.png" alt="" srcset="">
                        <img src="../assets/img/icons/fleche_pt.png" alt="" srcset="">
                    </div>
                </header>
                <section>
                    <app-accordion-complete />
                    <app-card-complete class="designSpec-cardMini"/>
                </section>
                
                <footer class="designSpec-footer">
                    <app-texts />
                </footer>
            </div>
        </div>
    </div>
</template>

<script>
import AppTexts from "@/components/Texts/AppTexts.vue";
import AppAccordionComplete from "@/components/Accordion/AppAccordionComplete.vue";
import AppCardComplete from '@/components/Card/AppCardComplete.vue';



export default {
    name: "DesignSpec",

    components: {
        AppTexts,
        AppAccordionComplete,
        AppCardComplete
    },

    data() {
        return {
            darkMode: false,
            theme: 'light'
        }
    },

    methods: {
        handleSelect() {
            this.darkMode = !this.darkMode
            document.documentElement.setAttribute('data-theme', this.theme)
        }
    }
}
</script>