<template>
    <div class="color-fidelity">
        <div class="card">
            <h2 class="card-subtitleTwo">Fidélité - Collecte du ticket</h2>
            
            <div class="card-fidelityHeaderUsed card-fid-position">
                <div class="card-scopes">
                    <div class="card-scopeOneHeader">En point(s)</div>
                    <div class="card-scopeOneHeader">En Euro(s)</div>
                </div>

                <div class="card-scopes">
                    <div class="card-scopeDetail">
                        <slot name="point" />
                    </div>
                    <div class="card-scopeDetail">
                        <slot name="value_point" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
    name: "AppCard"
}
</script>