<template>
    <app-accordion>
        <template v-slot:title>
            Repères nutritionnels pour 100g
        </template>

        <template v-slot:content>
            <template v-if="info.carbohydrates100g">
                <ul>
                    <li>
                        <div v-if="info.carbohydrates100g" class="templateProduct-chips color">Gludides {{ info.carbohydrates100g }}</div>
                    </li>
                </ul>
            </template>
            
            <div v-else class="templateProduct-noContent">
                Aucune information
            </div>
        </template>
    </app-accordion>
</template>

<script>
import AppAccordion from "@/components/Accordion/AppAccordion";

export default {
    name: 'AppDataDetailProductBenchmark',

    components: {
        AppAccordion
    },

    props: {
        info: {
            type: Object,
            required: true
        }
    },
}
</script>