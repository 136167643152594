import axios from "axios";
import { Base_url, new_api_key } from "../assets/js/constants.js";

export async function getTransactionOrder(token) {
    let authenticate = await axios({
        method: 'post',
        headers: {
            "Authorization": new_api_key,
        },
        url: Base_url + `/webfront/token/authenticate`,
        
        data: {
            token,
        },
    });

    return authenticate.data;
}

export async function getDetailProduct(productEAN) {
    let products = await axios({
        method: 'get',
        url: Base_url + `/internal/product/details/ean/${productEAN}`,
        headers: {
            "Authorization": new_api_key,
        }
    });

    return products.data;
}