<template>
    <app-accordion>
        <template v-slot:title>
            Information nutritionnelles
        </template>
        <template v-slot:content>
            <template v-if="valueComponentsExist(info)">
                <div class="templateProduct-contentComponent">
                    <div>Energie</div>
                    <div>{{ getEnergyKJ(info.energyKj100g) }} ({{ getEnergyKcal(info.energyKj100g) }})</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Matières grasses</div>
                    <div>{{ getExistValue(info.fat100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Acides gras saturé</div>
                    <div>{{ getExistValue(info.saturatedFat100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Glucides</div>
                    <div>{{ getExistValue(info.carbohydrates100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Sucres</div>
                    <div>{{ getExistValue(info.sugars100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Fibres alimentaires</div>
                    <div>{{ getExistValue(info.fiber100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Protéines</div>
                    <div>{{ getExistValue(info.proteins100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Sel</div>
                    <div>{{ getExistValue(info.salt100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div>Alcool</div>
                    <div>{{ getExistValue(info.alcohol100g) }}</div>
                </div>

                <div class="templateProduct-contentComponent">
                    <div class="templateProduct-text">Fruits, légumes, noix et huiles de colza, noix et olive (estimation par analyse de la liste des ingrédients)</div>
                    <div>{{ getExistValue(info.fruitsVegetablesNuts100g) }}</div>
                </div>
            </template>

            <div v-else class="templateProduct-noContent">
                Aucune information
            </div>
        </template>
    </app-accordion>
</template>

<script>
import AppAccordion from "@/components/Accordion/AppAccordion";

export default {
    name: 'AppDataDetailBenchmarkProductValue',

    components: {
        AppAccordion
    },

    props: {
        info: {
            type: Object,
            required: true
        }
    },
    
    methods: {
        valueComponentsExist(value) {
            return !value.energyKj100g 
            && !value.fat100g 
            && !value.saturatedFat100g 
            && !value.carbohydrates100g 
            && !value.sugars100g 
            && !value.fiber100g 
            && !value.proteins100g 
            && !value.salt100g 
            && !value.alcohol100g 
            && !value.fruitsVegetablesNuts100g ? false : true;
        },

        getEnergyKJ(value) {
            return (value !== null && value !== undefined) ? parseInt(value) + ' kj' : 'N/C*';
        },

        getEnergyKcal(value) {
            return (value !== null && value !== undefined) ? parseInt(value * 0.2388) + ' kcal' : 'N/C*';
        },

        getExistValue(value) {
            return (value !== null && value !== undefined) ? (value).toFixed(1) + ' g' : 'N/C*';
        },
    }
}
</script>