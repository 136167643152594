<template>
    <div class="header">
        <h5 class="header-subtitleFive">DÉTAILS DES ACHATS</h5>
        <button @click="closeScreen">
            <img src="../../assets/img/icons/close.png" alt="" srcset="">
        </button>
    </div>
</template>

<script>
export default {
    name: 'AppHeader',

    methods: {
        closeScreen() {
            const message = {
                status: "CLOSE_WINDOW",
            };
            top.postMessage(message, "*");
        }
    }
}
</script>