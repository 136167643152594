<template>
    <div class="dataDetailPayment" v-if="detailProduct">
        <div class="dataDetailPayment-total">
            <div>Total avant remise</div>
            <div>{{ isExist(roundedTwoValue(totalAmountPaid)) }} €</div>
        </div>

        <div v-if="isExist(totalDiscount)" class="dataDetailPayment-total">
            <div>Total réduction</div>
            <div>{{ isExist(roundedTwoValue(totalDiscount)) }} €</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDataDetailPayment',

    props: {
        detailProduct: {
            type: Object,
            require: true
        }
    },

    computed: {
        discount() {
            if(!this.detailProduct) return;

            return this.detailProduct;
        },
        totalAmountPaid() {
            if(!this.detailProduct) return;

            return this.detailProduct?.totalAmountPaid;
        },
        reductions(value) {
            if(!this.detailProduct) return;

            let sumRewardCash = 0;

                for (let j  = 0; j < this.detailProduct?.transactionOrderLines.length; j++) {
                    sumRewardCash += this.detailProduct?.transactionOrderLines[j]?.[value];
                }

            return  sumRewardCash;
        },
        rewardCash() {
            if(!this.detailProduct) return;

            let sumRewardCash = 0;

            
                for (let j  = 0; j < this.detailProduct?.transactionOrderLines.length; j++) {
                    sumRewardCash += this.detailProduct?.transactionOrderLines[j]?.rewardCash;
                }
            

            return  sumRewardCash;
        },
        reduction() {
            if(!this.detailProduct) return;

            let sumRewardCash = 0;

                for (let j  = 0; j < this.detailProduct?.transactionOrderLines.length; j++) {
                    sumRewardCash += this.detailProduct?.transactionOrderLines[j]?.reduction;
                }

            return  sumRewardCash;
        },
        totalDiscount() {
            return this.isExist(this.rewardCash) +
             this.isExist(this.reduction) + 
             this.isExist(this.discount.rewardArticle) + 
             this.isExist(this.discount.rewardCart) + this.isExist(this.discount.fidelity);
        }
    },

    methods: {
        isExist(value) {
            return (value !== null && value !== undefined) ? value : 0;
        },
        roundedTwoValue(value) {
            return (Math.round(value * 100) / 100).toFixed(2);
        },
    }
}
</script>