<template>
    <div class="templateProduct" v-if="this.$route.params.slug">
        <div class="templateProduct-card">
            <app-data-header-template-product @previousLink="goBack" />

            <div class="templateProduct-section" v-if="info">
                <app-data-score-product :info="infoScoreProduct" :isLoading="isLoading" />
                
                <app-list-ingredients :info="info" />
            </div>

            <app-error-no-content v-else />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_TEMPLATE_PRODUCT,
} from '../store/modules/receipts';

import AppDataHeaderTemplateProduct from "@/components/Data/AppDataHeaderTemplateProduct";
import AppDataScoreProduct from "@/components/Data/AppDataScoreProduct";
import AppListIngredients from "@/components/List/AppListIngredients";
import AppErrorNoContent from "@/components/Message/Error/AppErrorNoContent";

export default {
    name: "DetailProduct",

    components: {
        
        AppDataHeaderTemplateProduct,
        AppDataScoreProduct,
        AppListIngredients,
        AppErrorNoContent
    },

    async mounted() {
        await this.detailTemplateProduct(this.$route.params.slug);
        this.theme = this.displayCssId;
        
        this.handleSelect();
    },

    data() {
        return {
            isLoading: false,
            theme: null
        };
    },

    computed: {
        ...mapGetters([
            'displayInfoTemplateProduct',
            'displayDetailProduct',
            'displayCssId'
        ]),
        info() {
            return this.displayInfoTemplateProduct?.productDetails;
        },
        message() {
            return this.displayInfoTemplateProduct?.message;
        },
        infoScoreProduct() {
            return {
                productName: this.info?.productName ,
                imageUrl: this.info?.imageUrl,
                nutriscoreGrade: this.info?.imageUrl,
                novaGroup: this.info?.novaGroup,
                ecoscoreGradeFr: this.info?.ecoscoreGradeFr,
            }
        }
    },

    methods: {
        goBack() {
            this.$router.push({ path: `/transaction/${this.displayDetailProduct.token_url}` }); 
        },

        async detailTemplateProduct(slug) {
            this.isLoading = true;

            try {
                await this.$store.dispatch(GET_TEMPLATE_PRODUCT, {
                    slug,
                });
                this.isLoading = false;
            } catch (error) {
                console.log(error);
                this.isLoading = false;
            }
        },

        handleSelect() {
            document.documentElement.setAttribute('data-theme', this.theme)
        }
    }
}
</script>