import Vue from "vue";
import Vuex from "vuex";
import receipts from './modules/receipts';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        receipts
    }
})